<template>
  <div class="card notfound">
    <div class="card-body">
      Страница не найдена
    </div>
    <router-link to="/home">
      <button type="button" class="btn btn-primary">
        вернуться на главную
      </button>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "NotFound"
};
</script>
<style scoped>
.notfound {
  margin: 15% auto;
  display: table;
  text-align: center;
  padding: 10px;
}
</style>
